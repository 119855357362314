<template>
  <l-default>
    <OTitle>Login</OTitle>
    <c-form-login @success="onSuccess"></c-form-login>
    <CAdBitMarketPulse/>
  </l-default>
</template>

<script>
import CFormLogin from '../../forms/CFormLogin'
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapMutations } from 'vuex'
import CAdBitMarketPulse from '@/components/CAdBitMarketPulse.vue'

export default {
  name: 'PageLogin',
  components: {
    CAdBitMarketPulse,
    OTitle,
    LDefault,
    CFormLogin
  },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Login',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Login'
      },
      {
        name: 'keywords',
        content: 'myphonerobot login'
      }
    ]
  },
  props: {
    redirect: String
  },
  methods: {
    ...mapMutations({
      setGlobalMessage: 'setMessage'
    }),
    onSuccess ({ message }) {
      const page = 'home'
      this.$router.push(this.redirect ? { path: this.redirect } : { name: page })
      this.setGlobalMessage({ message: message })
    }
  }
}
</script>
